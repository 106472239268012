import classNames from 'classnames';
import styles from './ContactPerson.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Container } from 'ui/components/4-habitats/Container/Container';
import ColorOptions, { LinkType } from 'helpers/types';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';

export interface ContactPersonProps {
	className?: string;
	bgColor?: ColorOptions;
	heading?: string;
	ctaButton?: LinkType;
	image: {
		url: string;
		altText: string;
		focalPoint?: { top: number; left: number };
	};
	imageCropping?: 'cover' | 'contain';
	details?: { name: string; role: string; email: string; phoneNumber: string; linkedIn: { url: string; name: string } };
}

export const ContactPerson: React.FC<ContactPersonProps> = ({ className, bgColor, heading, ctaButton, details, image, imageCropping }) => {
	imageCropping = imageCropping || 'cover';

	return (
		<div className={classNames(styles.ContactPerson, styles[`u-color___${bgColor}`], className)}>
			<Container width="Standard">
				<TopLevelGrid container wrap>
					<TopLevelGridCell desktopWidth="22col" mobileWidth="100" desktopOffset="2col" offsetDirection="right">
						<Grid container wrap largeGutter>
							<GridCell desktopWidth="66" mobileWidth="100">
								<div className={styles.ContactPerson_infoWrapper}>
									<div className={styles.ContactPerson_infoContainer}>
										<div>
											{heading && (
												<Heading className={classNames(styles.ContactPerson_heading)} headingLevel="h2" style="md">
													{heading}
												</Heading>
											)}

											{ctaButton && (
												<Link className={styles.ContactPerson_ctaButton} href={ctaButton.url} style="lg">
													{ctaButton.name}
												</Link>
											)}
										</div>

										{image && (
											<Picture
												className={styles.ContactPerson_picture}
												{...image}
												focalPoint={image?.focalPoint}
												desktopNoShow={true}
												imageCropping="cover"
											/>
										)}

										{details && (
											<div className={styles.ContactPerson_details}>
												{(details.name || details.role) && (
													<div className={styles.ContactPerson_detailsColumn}>
														{details.name && <Paragraph>{details.name}</Paragraph>}
														{details.role && <Paragraph>{details.role}</Paragraph>}
													</div>
												)}

												{(details.email || details.phoneNumber) && (
													<div className={styles.ContactPerson_detailsColumn}>
														{details.email && <Link href={`mailto:${details.email}`}>{details.email}</Link>}
														{details.phoneNumber && (
															<Link href={`tel:${details.phoneNumber}`}>{details.phoneNumber}</Link>
														)}
													</div>
												)}

												{details.linkedIn && (
													<div className={styles.ContactPerson_detailsColumn}>
														<Link href={details.linkedIn.url}>{details.linkedIn.name}</Link>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</GridCell>
							<GridCell desktopWidth="33" mobileWidth="100">
								{image && (
									<div className={styles.ContactPerson_imageContainer}>
										<Picture
											{...image}
											focalPoint={{
												top: image.focalPoint?.top,
												left: image.focalPoint?.left,
											}}
											mobileNoShow={true}
											imageCropping={imageCropping}
											sizes="(max-width: 768px) 100vw, 460px"
										/>
									</div>
								)}
							</GridCell>
						</Grid>
					</TopLevelGridCell>
				</TopLevelGrid>
			</Container>
		</div>
	);
};
